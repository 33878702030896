/* Jnauary 6, 2025:  styles.css */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
  }
  
  .left-panel {
      width: 70%;
      background-color: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .right-panel {
      width: 30%;
      background-color: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .container {
    max-width: 1600px;
    margin: 0% auto;
    padding: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .container h1 {
    font-size: 2em;
    /* color: #444; */
    color: #f80707;
    margin-bottom: 0.5em;
    text-align: center;
  }
  
  .container h2 {
    font-size: 1em;
    color: #1507da;
    text-align: center;
    font-weight: bold;
    font-style: italic;
  }
  
  p {
    color: black;
    text-align: justify;
  }

  a {
    color: #007BFF;
    text-decoration: none;
  }

  a:hover {
    color: #007BFF;
    text-decoration: underline;
  }

  ul {
    list-style: disc;
    margin: 20px 0;
    padding-left: 40px;
    text-align: justify;
  }
  
  ul li {
    color:#007BFF
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
    color: black;
  }
  
  table th {
    border: 1px solid #ddd;
    padding: 10px;
    background-color: #bbbaba;
    color:black;
    font-weight: bold;
  }
  
  table td a {
    color: #007BFF;
    text-decoration: none;
  }
  
  table td a:hover {
    text-decoration: underline;
  }
  