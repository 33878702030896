.threeStagesGrid
{
    display: grid;
    grid-template-columns: 50% 50%;
}

.contractors
{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.us
{
    width: 100%;
    height: 131px;
}

.oddOptions
{
    display: grid;
    grid-template-columns: 80% 20%;
}

.evenOptions
{
    display: grid;
    grid-template-columns: 20% 80%;
}

.diy
{
    width: 100%;
}

.widthImage
{
    display: none;
}

@media only screen and (max-width: 800px) {
    .threeStagesGrid
    {
        grid-template-columns: 100%;
    }
    
}

@media only screen and (max-width: 950px) {
    .contractors
    {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .us
    {
        width: 110%;
        margin-left: -10%;
    }
}

@media only screen and (max-width: 700px) {
    .contractors
    {
        display: none;
    }
    .us
    {
        width: 120%;
        margin-left: -20%;
        display: none;
    }
    .oddOptions
    {
        grid-template-columns: 100%;
    }

    .evenOptions
    {
        grid-template-columns: 100%;
    }

    .diy
    {
        display: none;
    }

    .widthImage
    {
        display: inline;
        width: 200px;
    }
}