.homepageStructure {
    display: grid;
    grid-template-columns: 50% 45%;
}

.homepageFooter {
    display: flex;
    margin-left: 5%;
    margin-right: 5%;
}

.paragraphText{
    text-align: justify;
    font-size: 18px;
    color: black; /* font color */
    line-height: 150%;
    margin-top:auto;
    margin-bottom: auto;
 }

.paragraphText p1 {
   text-align: left;
   font-size: 18px;
   line-height: 150%;
}

.paragraphText h2 {
    margin: 50px 0;
    text-align: center;
    font-size: 48px;
}

/*
.widthSmallHome {
    display: none;
}

@media only screen and (max-width: 1300px) {
    #paragraph1
    {
        width: 80%;
    }
}

@media only screen and (max-width: 1050px) {
    .homepageStructure
    {
        grid-template-columns: 100%;
    }
    .widthLarge
    {
        display: none;
    }
    .widthSmallHome
    {
        display: inline;
    }
}
*/