.portfolioImage
{
    overflow: hidden;
    width: 97%;
    height: 318px;
    margin-bottom: 2vh;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.portfolioImage img
{
    transition: transform .5s ease;
}

.portfolioImage:hover img {
    transform: scale(1.15);
  }

.portfolioGrid
{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

@media only screen and (max-width: 1500px) {
    .portfolioGrid
    {
        grid-template-columns: 50% 50%;
    }
}

@media only screen and (max-width: 1000px) {
    .portfolioGrid
    {
        grid-template-columns: 100%;
    }

    .portfolioImage
    {
        width: 90%;
        height: 500px; 
    }
}

@media only screen and (max-width: 800px) {

    .portfolioImage
    {
        height: 400px;
    }
}

@media only screen and (max-width: 650px) {

    .portfolioImage
    {
        height: 300px;
    }
}

@media only screen and (max-width: 450px) {

    .portfolioImage
    {
        height: 250px;
    }
}


.testimonialWidth
{
    margin-left: auto;
    margin-right: auto;
    width: 70%;
}

@media only screen and (max-width: 1250px) {
    .testimonialWidth
    {
        width: 100%;
    }
}

.markKevin
{
    display: grid;
    grid-template-columns: 20% 80%;
    margin-bottom: 3vh;
}

.lori
{
    display: grid;
    grid-template-columns: 80% 20%;
    margin-bottom: 3vh;
}

.markKevinParagraph
{
    margin-right: 60px;
    font-size: 18px;
    line-height: 150%;
    margin-top: auto;
    margin-bottom: auto;
}

.loriBig
{
    display: inline;
}

.loriSmall
{
    display: none;
}

@media only screen and (max-width: 900px) {
    .markKevin
    {
        grid-template-columns: 30% 70%;
    }
    .lori
    {
        grid-template-columns: 70% 30%;
    }
}



@media only screen and (max-width: 650px) {
    .markKevin
    {
        grid-template-columns: 40% 60%;
    }
    .lori
    {
        grid-template-columns: 60% 40%;
    }
}

@media only screen and (max-width: 500px) {
    .markKevin
    {
        grid-template-columns: 100%;
    }
    .lori
    {
        grid-template-columns: 100%;
    }
    .markKevinParagraph
    {
        margin-left: 20px;
        margin-right: 20px;
    }
    .loriBig
    {
        display: none;
    }

    .loriSmall
    {
        display: inline;
    }
}