body {
    font-family: Arial, sans-serif;
    background-color: #e6f7f5;
    margin: 0;
    padding: 20px;
}
.container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.left-panel {
    width:50%;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.right-panel {
    width:50%;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.header {
    font-size: 18px;
    margin-bottom: 50px;
    margin-left: 4rem;
    margin-right: 5em;
    text-align: justify;
}

.contact-info {
    margin-bottom: 60px;
}
.contact-group {
    margin-bottom: 25px;
}
.contact-info p {
    margin: 1px 5px;
    text-align: center;
}
.form-group {
    margin-bottom: 15px;
}
.form-group label {
    display: block;
    margin-bottom: 5px;
    text-align: left;
}
.form-group input, .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
.form-group textarea {
    height: 200px;
    resize: none;
    text-align: left;
}
.submit-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left:auto;
    margin-right: auto;
    display: grid;
}
.submit-button:hover {
    background-color: #005f55;
}
.disclaimer {
    font-size: 12px;
    margin-top: 15px;
}