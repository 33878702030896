body {
    font-family: Arial, sans-serif;
}

.section-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.list-disc {
    list-style-type: disc;
}

.nested-list {
    list-style-type: circle;
    margin-left: 1.5rem;
}

.plans-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
}

.plans-table th,
.plans-table td {
    border: 1px solid #ddd;
    padding: 0.75rem;
    text-align: left;
}