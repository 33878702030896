.navigationBar {
    display: grid;
    margin-left: 10em;
    margin-right: 5em;
}

.fitSize {
    width: 100px;
    margin-left: 5em; 
    float: left;
    margin-right: 10px;
}

nav {
    background-color:aliceblue;
}

body {
    font-family: Arial, sans-serif;
}

.nav-links {
    list-style-type:none;
    display: flex;
    justify-content: center;
}
  
.nav-links li {
    position:relative;
    list-style: none;
    padding: 10px;
    margin: 0px 30px;
    animation-fill-mode: forwards;
    font-size: 20px;
    font-weight: bold;
}
  
.nav-links a {
    color: black;
    padding: 15px;
    text-decoration: none;
    display:contents;
}
  
/* Hover effect for the navigation links */
.nav-links a:hover {
    background-color: azure;
}
  
/* Dropdown container (hidden by default) */
.dropdown-content {
    display: none;
    position: absolute;
    top: 80%;
    left: 0;
    background-color: aliceblue;
    min-width: max-content;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}
  
.dropdown-content li {
    display: block;
    font-size: 15px;
}
  
/* Show the dropdown content when hovering over the dropdown item */
.dropdown:hover .dropdown-content {
    /* display: contents; */
    display: block;   
}

.hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color:black;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
  
.hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}


  
/*  January 8, 2025:  Commented out. 

@media only screen and (max-width: 950px) {
    .topGrid
    {
        display: none;
    }
}

.toggle-button
{
    top: 12px;
    left: 12px;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 35px;
    height: 21px;
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 20px;
}


.toggle-button .bar
{
    height: 3px;
    width: 100%;
    background-color: black;
    border-radius: 10px;
    z-index: 2;
}



@media only screen and (max-width: 950px) {
    .toggle-button
    {
        display: flex;
    }

    nav ul
    {
        display: none;
    }

    nav ul1
    {
        display: inline;
        z-index: 1;
        background-color: white;
    }
}


nav ul
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}


.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: black;
}

.hover-underline-animation::after 
{
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color:black;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
  
.hover-underline-animation:hover::after 
{
    transform: scaleX(1);
    transform-origin: bottom left;
}

.hover-underline-animation-white {
    display: inline-block;
    position: relative;
    color: white;
}
  
.hover-underline-animation-white::after 
{
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color:white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
  
.hover-underline-animation-white:hover::after 
{
    transform: scaleX(1);
    transform-origin: bottom left;
}

nav ul li a
{
    text-decoration: none;
    text-align: left;
}

*/