.learnMoreBoxAbout
{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    background: transparent;
    border: 1px solid white;
    border-radius: 0rem;
    padding: 1rem;
    text-decoration: none;
    width: 100px;
    height: 25px;
    font-size: 20px;
}

.learnMoreOutlineAbout
{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
}

.hover-highlight-animation-about {
    display: inline-block;
    position: relative;
    color: white;
}
  
.hover-highlight-animation-about::after 
{
    content: '';
    padding-top: 17px;
    color: black;
    position: absolute;
    width: 101%;
    transform: scaleY(0);
    height: 42px;
    margin-top: -17px;
    text-align: center;
    margin-left: calc(-100% + 18.25px);
    background-color: white;
    transform-origin: top;
    transition: transform 0.25s ease-out;
}
  
.hover-highlight-animation-about:hover::after 
{
    transform: scaleY(1);
    transform-origin: bottom;
    content: 'Learn More';
}

p {
    text-align:justify;
    width: 80%;
    margin-left: 10%; 
    font-size: 18px;
}

h1 {
    /* style={{"display": "grid", "marginBottom": "3vh"}} */
    text-align:center;
}