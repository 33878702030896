.bigHeader
{
    color: white;
    position: absolute;
    font-size: 68px;
}

#specialHeader
{
    position: absolute;
    width: calc(100% - 2em);
}

#morningGloryHeader
{
    margin-top: 44vh;
    margin-left: 11vw;
}

.morningGloryLearnMore
{
    position: absolute;
    margin-top: 52vh;
    margin-left: 11vw;
}

#lotusHeader
{
    margin-top: 134vh;
    margin-left: 66.5vw;
}

.lotusLearnMore
{
    position: absolute;
    margin-top: 142vh;
    margin-left: 68vw;
}

#sunflowerHeader
{
    margin-top: 247vh;
    margin-left: 11vw;
}

@media only screen and (max-width: 950px) {
    #lotusHeader
    {
        margin-left: 11vw;
    }
    .lotusLearnMore
    {
        margin-left: 11vw;
    }
}

@media only screen and (max-width: 550px) {
    #morningGloryHeader
    {
        width: 80%;
        margin-top: 33vh;
    }
}

@media only screen and (max-width: 360px) {
    .bigHeader
    {
        font-size: 55px;
    }
    #morningGloryHeader
    {
        width: 80%;
        margin-top: 37vh;
    }
}

.homesImage
{
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.homesMainImage
{
    height: 80vh;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
}

#avalon0
{
    margin-left: 4%;
    margin-top: 2%;
}

#avalon1
{
    margin-left: 45%;
    margin-top: 36%;
}

#avalon3
{
    margin-left: 27%;
    margin-top: 15%;
}
#avalon4
{
    margin-left: 24%;
    margin-top: 8.5%;
}
#avalon5
{
    margin-left: 33%;
    margin-top: 16%;
}

@media only screen and (max-width: 1550px) {
    #avalon0
    {
        margin-left: 4%;
        margin-top: 2%;
    }

    #avalon1
    {
        margin-left: 47%;
        margin-top: 40%;
    }

    #avalon3
    {
        margin-left: 27%;
        margin-top: 17%;
    }
    #avalon4
    {
        margin-left: 24%;
        margin-top: 9%;
    }
    #avalon5
    {
        margin-left: 34%;
        margin-top: 17.5%;
    }
}

@media only screen and (max-width: 1410px) {
    #avalon0
    {
        margin-left: 4%;
        margin-top: 2%;
    }

    #avalon1
    {
        margin-left: 50%;
        margin-top: 43%;
    }

    #avalon3
    {
        margin-left: 29%;
        margin-top: 18%;
    }
    #avalon4
    {
        margin-left: 25%;
        margin-top: 10%;
    }
    #avalon5
    {
        margin-left: 36%;
        margin-top: 19%;
    }
}