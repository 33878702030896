.profileBox
{
    background-color: #187cb8;
    width: 1300px;
    clip-path: polygon(100% 0,100% 75%,80% 100%,0 100%,0 0);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding-top: 91.5px;
    padding-bottom: 91.5px;
}

.profileInnerBox
{
    display: grid;
    grid-template-columns: 25% 75%;
    width: 100%;
    color: white;
}

.profileInfoBox
{
    display: grid;
    grid-template-columns: 35% 65%;
    height: 15vh;
}

.profileMargin
{
    margin: 10px;
}

#profileLighterFont
{
    font-weight: normal;
}

.profileImage
{
    height: 300px;
    width: 237.5px;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
}

.widthSmaller
{
    display: grid;
    grid-template-columns: 50% 50%;
}

.bio
{
    width: 720px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10vh;
}

@media only screen and (max-width: 1320px) {
    .profileBox
    {
        background-color: #187cb8;
        width: 920px;
        clip-path: polygon(100% 0,100% 75%,80% 100%,0 100%,0 0);
        margin-left: auto;
        margin-right: auto;
        display: flex;
        padding-top: 91.5px;
        padding-bottom: 50px;
    }

    .profileInnerBox
    {
        display: grid;
        grid-template-columns: 40% 60%;
        width: 100%;
        color: white;
    }

    .widthSmaller
    {
        grid-template-columns: 100%;
    }

    .profileInfoBox
    {
        height: auto;
    }
}

@media only screen and (max-width: 950px) {
    .profileBox
    {
        background-color: #187cb8;
        width: 700px;
        clip-path: polygon(100% 0,100% 75%,80% 100%,0 100%,0 0);
        margin-left: auto;
        margin-right: auto;
        display: flex;
        padding-top: 50px;
        padding-bottom: 270px;
    }

    .profileInnerBox
    {
        display: grid;
        grid-template-columns: 100%;
        width: 100%;
        color: white;
    }

    .profileInfoBox
    {
        margin-top: 40px;
        grid-template-columns: 30% 70%;
        height: 15vh;
    }

    #specialProfile
    {
        grid-template-columns: 40% 60%;
    }

    .widthSmaller
    {
        display: grid;
        grid-template-columns: 50% 50%;
    }
    #secondSpecial
    {
        margin-top: auto;
    }
    .bio
    {
        width: 680px;
    }
}

@media only screen and (max-width: 770px) {
    .profileBox
    {
        background-color: #187cb8;
        width: 100%;
        clip-path: polygon(100% 0, 100% 90%, 0 100%, 0 100%, 0 0);
        margin-left: auto;
        margin-right: auto;
        display: flex;
        padding-top: 50px;
        padding-bottom: 100px;
    }

    .profileInfoBox
    {
        grid-template-columns: 45% 55%;
        height: auto;
    }

    #specialProfile
    {
        grid-template-columns: 45% 55%;
    }

    .widthSmaller
    {
        display: grid;
        grid-template-columns: 100%;
    }
    #secondSpecial
    {
        margin-top: auto;
    }
    .bio
    {
        width: 90%;
    }
}

@media only screen and (max-width: 310px) {
    .profileInfoBox
    {
        grid-template-columns: 45% 55%;
    }

    #specialProfile
    {
        grid-template-columns: 45% 55%;
    }
}