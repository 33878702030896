/* Jnauary 6, 2025:  styles.css */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
  }
  
  .left-panel {
      width: 60%;
      background-color: #f9f9f9;
      padding: 20px;
      box-shadow: 0 2px 4px #f9f9f9;
  }
  .right-panel {
      width: 40%;
      background-color: #f9f9f9;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 4px #f9f9f9;
  }

  .container {
    max-width: 1500px;
    margin: 0% auto;
    margin-left: 5%;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .container h1 {
    font-size: 2em;
    color: black;
    margin-bottom: 0.5em;
    text-align: center;
  }
  
  .container h2 {
    font-size: 1em;
    color: #1507da;
    text-align: center;
    font-weight: bold;
    font-style: italic;
  }
  
  p {
    color: black;
    text-align: justify;
  }

  a {
    color: #007BFF;
    text-decoration: none;
  }

  a:hover {
    color: #007BFF;
    text-decoration: underline;
  }

  ul {
    list-style: disc;
    margin: 20px 0;
    padding-left: 180px;
    text-align: justify;
  }
  
  ul li {
    color:black
  }