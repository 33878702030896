a {
    color: #007BFF;
    text-decoration: none;
  }

a:hover {
    color: #007BFF;
    text-decoration: underline;
  }

ul {
    list-style:disc;
    margin: 20px 0%;
    margin-right: 10%;
    padding-left: 180px;
    text-align: justify;
  }
  
ul li {
    color:black;
  }