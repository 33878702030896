/* January 7, 2025 */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    text-align: center;
}

header {
    margin: 20px 0;
    background-color: #012345;
}

.logo {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-weight: bold;
    color: #000;
}

.logo img {
    width: 50px;
    margin-right: 10px;
}

.contact {
    margin: 20px 0;
    font-size: 18px;
}

.contact a {
    color: blue;
    text-decoration: none;
}

.contact a:hover {
    text-decoration: underline;
}

.details {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.details > div {
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 300px;
}

.details h3 {
    font-size: 16px;
    margin-bottom: 8px;
}

.details p {
    margin: 5px 0;
    line-height: 1.0;
    font-size: 14px;
}

footer {
    margin-top: 20px;
    font-size: 2px;
    color: #666;
}

img {
    width: 50px;
    height: auto; /* Maintain aspect ratio */
}