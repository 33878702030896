/*
.downloadBoxAbout
{
    display: flex;
    flex-direction: column;
    text-align: center;
    background: transparent;
    border: 1px solid black;
    border-radius: 0rem;
    padding: 1rem;
    text-decoration: none;
    width: 150px;
    height: 25px;
    font-size: 20px;
    margin-top: 40px;
}

.downloadOutlineAbout
{
    display: flex;
    flex-direction: column;
    text-align: center;
}

.hover-highlight-animation-download {
    display: inline-block;
    position: relative;
    color: black;
}
  
.hover-highlight-animation-download::after 
{
    content: '';
    padding-top: 17px;
    color: white;
    position: absolute;
    width: 101%;
    transform: scaleY(0);
    height: 42px;
    margin-top: -17px;
    text-align: center;
    margin-left: calc(-100% + 28.25px);
    background-color: black;
    transform-origin: top;
    transition: transform 0.25s ease-out;
}
  
.hover-highlight-animation-download:hover::after 
{
    transform: scaleY(1);
    transform-origin: bottom;
    content: 'Download Plan';
}

.planFlex
{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
}

.planImage
{
    width: 80%;
}

.planGrid
{
    display: grid;
    grid-template-columns: 40% 60%;
    margin-bottom: 5vh;
}

.planh1
{
    font-size: 36px;
}

.planp1
{
    font-size: 20px;
}

.smallImage
{
    display: none;
}

.largeImage
{
    display: inline;
}

@media only screen and (max-width: 1050px) {
    .planFlex
    {
        width: 90%;
    }
    .planImage
    {
        margin-top: auto;
        margin-bottom: auto;
    }
}

@media only screen and (max-width: 460px) {
    .planGrid
    {
        display: grid;
        grid-template-columns: 100%;
        width: 100%;

    }
    .largeImage
    {
        display: none;
    }
    .smallImage
    {
        display: inline;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
}

*/

/* January 7, 2025 */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #5a08b7;
    color: #05ee20;
}

.container {
    width: 88%;
    color: black
}

.planHeader {
    display: contents;
    color: black;
}

.planHeader h2 {
    margin: 10px;
    text-align: center;
    font-size: 28px;
    color: black;
}

.planHeader p {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2em;
    text-align: left;
    font-size: 18px;
    line-height: 150%;
 }

.planHeader a {
    color: #007BFF;
}

.planHeader a:hover {
    text-decoration: underline;
}

.plan {
    display:grid;
    grid-template-columns: 80% 20%;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 20px;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.plan img {
    max-width: 100px;
    border-radius: 8px;
    margin-right: 40px;
    margin-left: 40px;
}

.plan h2 {
    font-size: 28px;
    font-weight: bold;
    color: #007BFF;
    margin: 0 0 10px;
}

.plan p1 {
    margin-left: 10px;
    font-size: 16px;
}

.plan p2 {
    margin-left: 5%;
    margin-right: 10%;
    text-align: right;
    margin-bottom: 2em;
    font-size: 16px;
}

.plan a {
    text-decoration: none;
    color: #007BFF;
    font-size: 38px;
}

.plan a:hover {
    text-decoration: underline;
}

.plan-download {
    text-align: center;
}

.plan-download a {
    display: inline-block;
    padding: 20px 20px;
    background-color: #393b3e;  /* button color */
    color: #ffffff;  /* button text font color is white */
    border-radius: 25px;
    font-size: 16px;
}
